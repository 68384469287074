define("discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "discourse/lib/show-modal", "discourse/mixins/load-more", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _decorators, _object, _showModal, _loadMore, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_loadMore.default, dt7948.p({
    tagName: "",
    eyelineSelector: ".user",
    page: 1,
    loading: false,
    canLoadMore: true,
    currentUserRanking() {
      const user = this.model.personal;
      return user || null;
    },
    winners(users) {
      return users.slice(0, 3);
    },
    ranking(users) {
      users.forEach(user => {
        if (user.id === this.currentUser?.id) {
          user.isCurrentUser = "true";
        }
      });
      return users.slice(3);
    },
    showLeaderboardInfo() {
      (0, _showModal.default)("leaderboard-info");
    },
    loadMore() {
      if (this.loading || !this.canLoadMore) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)(`/leaderboard/${this.model.leaderboard.id}?page=${this.page}`).then(result => {
        if (result.users.length === 0) {
          this.set("canLoadMore", false);
        }
        this.set("page", this.page += 1);
        this.set("model.users", this.model.users.concat(result.users));
      }).finally(() => this.set("loading", false)).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "currentUserRanking", [(0, _decorators.default)("model.users")]], ["method", "winners", [(0, _decorators.default)("model.users")]], ["method", "ranking", [(0, _decorators.default)("model.users.[]")]], ["method", "showLeaderboardInfo", [_object.action]], ["method", "loadMore", [_object.action]]]));
});